.first_profile {
  width: 100%;
  max-width: 70%;
  margin: auto;
  height: 100vh;
  .titleforuser {
    margin-top: 1px;
    margin-bottom: 0;
    margin-left: 0;
    font-size: 21px;
  }
}
.user-list {
  justify-content: center;
  align-items: center;
}

.editbtn {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;

  // position: absolute;
  bottom: 0;
  right: 0;
  // width: 20px;
  img {
    width: 20px;
    margin-top: 112px;
  }
}

.delbtn {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  // position: absolute;
  bottom: 0;
  right: 0;
  // width: 20px;
  img {
    width: 20px;
    margin-top: 42px;
    margin-left: -29px;
  }
}

.icon_bg {
  width: 100%;
  max-width: 162px;
  height: 162px;

  text-align: center;
  background: #3f535e;
  border-radius: 50%;
  padding: 32px 0;
  img {
    width: 100%;
    max-width: 88px;
    height: 95px;
  }
}
// .account-list:hover{
//   filter: blur(8px);
//   -webkit-filter: blur(8px);
// }

.title-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  .edit-button {
    background: transparent;
    border: none;
    cursor: pointer;
    img {
      width: 20px;
    }
  }
}
