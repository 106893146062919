.dsadad {
  background: #171c29 !important;
  border-radius: 12px !important;
  padding: 1rem 1rem !important;
  margin-top: 20px !important;
  .form-group {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    margin-bottom: 1.5rem;
    text-align: left;
    label {
      color: #fff;
      font-size: 20px;
      margin-bottom: 5px;
    }
    input {
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      appearance: none;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:focus-visible {
        outline: none;
      }
    }
  }
  .modal-button {
    text-align: center;
    padding-top: 60px;
    button {
      border: none;
      color: #fff;
      background-color: #4385b6;
      border-color: #4385b6;
      font-size: 1.125rem;
      font-family: "Inter", sans-serif;
      font-weight: 700;
      padding: 8px 12px;
      border-radius: 8px;
      margin-right: 30px;
      &:hover {
        background-color: #5f97c1;
        border-color: #5691bd;
      }
    }
  }

  .cvv-text {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
  .cvv-input {
    margin-left: 46px;
  }
}

.plane-sec {
  padding-top: 30px;
  text-align: center;
  .main {
    height: 380px;
  }
  .plane-heading {
    padding-bottom: 20px;
    h2 {
      color: #fff;
    }
  }
  .membership-card__cards-new {
    display: flex;
    align-items: center;
    justify-content: center;
    // .col-md-4{
    //     width: 48%;
    // }
    .pricing-card__pricing {
      h1 {
        font-size: 39px;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: #20484f;
      }
    }
    .pricing-card-new {
      background: #f5f5f5;
      border-radius: 24px;
      margin-left: 10px;
      width: 250px;
      padding: 20px;
      @media (max-width: 768px) {
        width: 320px;
        height: 493px;
        margin-bottom: 10px;
      }
      .pricing-card__heading {
        h2 {
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          color: #20484f;
          font-family: "Inter", sans-serif;
          text-transform: uppercase;
        }
        span {
          font-family: "Plus Jakarta Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          color: #20484f;
        }
      }
      .pricing-card__lists {
        height: 200px;
        // padding: 0px 90px;
        ul {
          /* position: relative; */
          /* position: relative; */
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          color: #072125;
          /* padding: 22px; */
          padding: 0;
          li {
            font-size: 17px;
            list-style: none;
          }
        }
      }
      .pricing-card__button {
        // padding-left:20px;
        // padding-right: 20px;

        button {
          padding: 9px 0 15px;
          width: 100%;
          max-width: 314px;
          height: 100%;
          max-height: 58px;
          border-radius: 8px;
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          color: #ffffff;
          border: none;
          outline: none;
          transition: 0.5s ease-in-out;
          background: #46a065;
          border: 1px solid #46a065;
          // &:first-child{
          //     padding-top: 40px;
          // }
        }
        .blue {
          padding: 11px;
          width: 100%;
          max-width: 314px;
          height: 100%;
          max-height: 58px;
          border-radius: 8px;
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          color: #ffffff;
          border: none;
          outline: none;
          transition: 0.5s ease-in-out;
          background: #4d85c8;
          border: 1px solid #4d85c8;
        }
      }
    }
  }
  .pricing-card__lists__extra {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #20484f;
    }
  }
}
.plane-new-sec {
  background-color: #171c29;
  border-radius: 12px;
  padding: 3rem 6rem;
  margin-top: 30px;
  .new-plane-heading {
    h5 {
      font-weight: 800;
      font-size: 1.25rem;
      line-height: 0;
      text-align: center;
      color: #ffffff;
      text-align: left;
      /* margin-bottom: 5px; */
      padding-bottom: 30px;
    }
    p {
      color: #fff;
    }
  }
  .subcription-sec {
    padding-top: 35px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-right: 70px;
    .monthly-sec {
      background: #393939;
      width: 254px;
      padding: 23px 23px 17px;
      border-radius: 6px;
      text-align: left;
      h5 {
        margin: 0;
        padding-bottom: 24px;
        word-spacing: 19px;
        font-weight: 800;
        font-size: 1.25rem;
        line-height: 0;
        text-align: center;
        color: #ffffff;
        text-align: left;
        /* margin-bottom: 5px; */
        /* padding-bottom: 30px; */
      }
      p {
        margin: 0;
        color: #fff;
      }
    }
    .year-sec {
      background: #393939;
      width: 254px;
      padding: 23px 23px 17px;
      border-radius: 6px;
      text-align: left;
      height: 250px;
      h5 {
        margin: 0;
        padding-bottom: 24px;
        word-spacing: 19px;
        font-weight: 800;
        font-size: 1.25rem;
        line-height: 0;
        text-align: center;
        color: #ffffff;
        text-align: left;
        /* margin-bottom: 5px; */
        /* padding-bottom: 30px; */
      }
      p {
        margin: 0;
        color: #fff;
      }
    }
  }
}

.sub-wrapper {
  position: relative;
  text-align: center;
  display: inline-block;
  padding-left: 60px;

  span {
    background: #4385b6;
  }

  input[type="radio"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
  }

  input[type="radio"]:checked ~ .blueclass,
  input[type="radio"]:checked ~ .yearly_pop > .blueclass {
    background: #4385b6 !important;
  }
}
.yearly_pop {
  span {
    background: #4385b6;
    color: #fff !important;
    padding: 4px 16px;
    border-radius: 3px;
    text-align: center;
    position: absolute;
    top: -11px;
    /* left: 0; */
    /* right: 127px; */
    left: 31%;
    transform: translate(50%, -50%);
  }
}
.form-sec {
  padding-top: 64px;
  .form-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .headding-inner {
      h5 {
        color: #ffff;
      }
    }
    .heading-pic {
      img {
        width: 30px;
        padding-left: 5px;
      }
    }
  }
}

.form-sec {
  .from-group {
    display: flex;
    flex-direction: column;
    label {
      color: #fff;
      padding-bottom: 10px;
      padding-top: 20px;
    }
    input[type="text"],
    [type="tel"] {
      border-radius: 10px;
      border: 0;
      padding: 10px;
    }
  }
  p {
    color: #fff;
    padding-top: 30px;
  }
  .agree-button {
    text-align: center;
    button {
      width: 100%;
      padding: 15px;
    }
  }
}

.thank-you-sec {
  background-color: #171c29;
  border-radius: 12px;
  padding: 3rem 10rem;
  margin-top: 30px;
  color: #fff;
  width: 770px;

  .heading-text {
    .para {
      max-width: 450px;
      margin: 0 auto;
      padding-bottom: 25px;
    }
    span {
      line-height: 55px;
    }
  }
}
.thank-you-heading {
  h5 {
    font-weight: 800;
    font-size: 50px;
    padding-bottom: 20px;
  }
}
.thank-you-button {
  text-align: center;
  padding-top: 30px;
  button {
    width: 100%;
    padding: 15px;
  }
}

.agree-sec {
  background: #343434;
  border-radius: 5px;
  padding: 10px;
  .agree-text {
    p {
      color: #ffff;
      margin: 0;
      padding: 0;
      span {
        text-decoration: underline;
      }
    }
  }
  .agree-button {
    padding-top: 16px;
    padding-bottom: 8px;
  }
}

.check-box-section {
  i {
    background: #20484f;
    border-radius: 4px;
    color: #fff;
  }
}

.password-sec {
  .form-control {
    //     width: 447px;
    // height: 51px;
  }
  span {
    /* font-weight: 400; */
    /* font-size: 12px; */
    /* line-height: 150%; */
    color: #ffffff;
    /* padding-top: 7px; */
  }
  .submit-btn {
    padding-top: 103px !important;
    padding-bottom: 100px;
    // width: 447px !important;
  }
}

.enter-password-sec {
  .form-control {
    //     width: 447px;
    // height: 51px;
  }
  span {
    /* font-weight: 400; */
    /* font-size: 12px; */
    /* line-height: 150%; */
    color: #ffffff;
    /* padding-top: 7px; */
  }
  .submit-btn {
    padding-top: 139px !important;
    // width: 447px !important;
    // height: 51px;
    padding-bottom: 18px;
  }
  .forget-sec {
    span {
      color: #9dd5fd;
    }
  }
}

.thank-you-sec {
  .heading-text {
    p {
      max-width: 450px;
    }
  }
}

#logoo {
  height: calc(120 / 18 * 1rem);
  width: auto;
}
