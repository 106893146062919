.profile-selection-avatar{
    width: 100%;
    max-width: 40%;
    margin: auto;
    height: 100vh;
    @media(max-width:1366px){
        max-width: 47%;
    }
    @media(max-width:1100px){
        max-width: 55%;
    }
    @media(max-width:1024px){
        max-width: 80%;
    }
    @media(max-width:767px){
        max-width: 90%;
    }
h6{
    color:  #B9D6E5;
    
}
}