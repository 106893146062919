.submit-login-page-main-sec {
  .submit-login-page {
    padding: 120px 0;
    .left-sec-data-submit-page {
      .firts-heading-brain-data {
        padding-bottom: 15px;
        h2 {
          color: #fff;
          margin-bottom: 18px;
        }
        p {
          color: #fff;
          margin-bottom: 15px;
        }
      }

      .left-data-form-sec {
        .form-group {
          margin-bottom: 10px;
          label {
            color: #fff;
            margin-bottom: 8px;
          }
          input {
            color: #000;
            background: #fff;
            height: 50px;
            width: 100%;
            border-radius: 10px;
            padding-left: 20px;
          }

          textarea {
            width: 100%;
            height: 90px;
            border-radius: 10px;
            color: #000;
            padding-left: 20px;
          }
        }
      }

      .last-all-terms-condition-sec {
        margin: 20px 0 30px;
        display: flex;
        align-items: center;
        label {
          color: #fff;
          margin-left: 10px;
          cursor: pointer;
        }
        input {
          cursor: pointer;
          width: 25px;
          height: 25px;
          border: 1px solid #4385b6;
        }

        .form-check-input:checked {
          background-color: #4385b6;
        }
      }

      .last-submit-btn-sec {
        .submit-btn {
          color: #fff;
          background: #4385b6;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          border-radius: 10px;
          letter-spacing: 1.2px;
          font-family: "Inter", sans-serif;
          font-weight: 500;
          font-size: 18px;
          color: #ffffff;
          transition: 0.5s all ease-in-out;
          border: 1px solid #4385b6;
          &:hover {
            background: #fff;
            color: #4385b6;
          }
        }
      }
    }

    .right-submit-login-page-sec {
      padding-left: 50px;
      .right-first-login-btn-sec {
        padding-bottom: 40px;
        .login-btn {
          color: #fff;
          background: #4385b6;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 1.2px;
          border-radius: 10px;
          border: 1px solid #4385b6;
          font-weight: 500;
          font-size: 18px;
          padding: 0 50px;
          font-family: "Inter", sans-serif !important;
          transition: 0.5s all ease-in-out;
          &:hover {
            background: #fff;
            color: #4385b6;
          }
        }
      }

      .right-img-sec {
        figure {
          margin-bottom: 0;
          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
.submit-login-page-main-sec .submit-login-page .left-sec-data-submit-page .last-all-terms-condition-sec label {
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
  margin-top: 42px !important;
}

.submit-login-page-main-sec .submit-login-page .left-sec-data-submit-page .last-all-terms-condition-sec input {
  cursor: pointer;
  width: 52px !important;
  height: 26px;
  border: 1px solid #4385b6;
}