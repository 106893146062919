.modal-content {
  background-color: #1b2b3a;
  border-radius: 12px;
  padding: 31px;
  .modal-header {
    .modal-title {
      color: #ffff;
    }
    border: none;
  }
  .modal-body {
    .form-group {
      padding-bottom: 30px;
      label {
        color: #fff;
        padding-bottom: 10px;
      }
      input {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        border: 0;
        margin-bottom: 10px;
        &:focus-visible {
          outline: none;
        }
      }
    }
    .footer-btn {
      text-align: center;
    }
  }
}
