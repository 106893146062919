.form-group {
  position: relative;
}
.open-eye {
  position: absolute;
  top: 50px;
  right: 9px;
  cursor: pointer;
}


#logoo {
  height: calc(120 / 18 * 1rem);
  width: auto;
}
