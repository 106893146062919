.common_gap {
    padding-top: 10rem;
}
.testimonial{
    h2{
         font-family: "Inter", sans-serif !important;
        font-style: normal;
        font-weight: 700;
        font-size: 2.25rem;
        line-height: 3.5rem;
        color: #b9d6e5;
       text-align: center;
@media(max-width:767px){
    font-size: 28px;
}
    }
    h5{
        font-family:  "Inter", sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 44px;
        margin-bottom: 0;
       
        
        color: #fff;
    }
    p{
         font-family: "Inter", sans-serif !important;;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: #ffffff;
        padding-bottom: 0rem;
        margin-bottom: 0;
span{
    font-size: 20px;
    font-weight: 700;
    width: fit-content;
    border-bottom: 1px solid #fff;
}
    }
    .testimonial_box{
        border-radius: 8px;
        padding: 20px;
        background: rgba(70, 131, 178, 0.25);
        box-shadow: -2px 2px 2px 5px rgba(67, 130, 177, 0.11);
    }
    img{
        width: 100%;
        max-width: 100px;
        height: 100px;
        border-radius: 50%;
    }
}