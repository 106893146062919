.wraper {
  // height: calc(100vh - 13rem);
  background-color: rgba(#0f1625, 0.7);
  // border-radius: 20px;
  border: 3.5rem solid transparent;
  padding: 0 1rem;
  margin-bottom: 3rem;
  // overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #1d2635;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #364a6c;
    border-radius: 5px;
  }
  &.afterline {
    position: relative;
    &::after {
      content: "";
      width: 50%;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      position: absolute;
      bottom: -6rem;
      background: #3d3d3d;
      height: 1px;
    }
  }
}
.layer_one {
  h1 {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: calc(52 / 16 * 1rem);
    line-height: normal;
    @media (max-width: 1200px) {
      font-size: calc(35 / 16 * 1rem);
    }
    @media (max-width: 992px) {
      font-size: calc(25 / 16 * 1rem);
    }
    span {
      color: #46ace4;
    }
  }
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    /* or 175% */

    color: #ffffff;
  }
  a {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 1.3rem;

    color: #ffffff;
    @media (max-width: 991px) {
      font-size: 0.8rem;
    }
  }
}

.longDash {
  padding: 5rem 0;
  span {
    width: calc(500 / 16 * 1rem);
    height: 0px;
    display: block;
    margin: auto;
    border-top: 1px solid #3d3d3d;
  }
}

.layer_two {
  h2 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 3.5rem;
    color: #b9d6e5;
    margin-bottom: 5rem;
    @media (max-width: 1200px) {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
    @media (max-width: 992px) {
      font-size: 1rem;
    }
  }
  ul {
    li {
      font-family: "Inter", sans-serif;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: #ffffff;
      padding-bottom: 2rem;
      @media (max-width: 1200px) {
        font-size: 1.1rem;
        line-height: 1.5rem;
      }
      @media (max-width: 992px) {
        font-size: 0.8rem;
      }
    }
  }
}

.layer_three {
  h2 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 3.5rem;
    color: #b9d6e5;
    margin-bottom: 5rem;
    @media (max-width: 1200px) {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
    @media (max-width: 992px) {
      font-size: 1rem;
    }
  }
  .workCard {
    .head {
      position: relative;
      img {
        width: 100%;
      }
      .text {
        font-family: "Inter", sans-serif;
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        width: 69%;
        font-weight: 400;
        font-size: 1.5rem;
        color: #ffffff;
        user-select: none;
        text-align: center;
        @media (max-width: 992px) {
          font-size: 1.2rem;
        }
      }
    }
    .body {
      p {
        font-family: "Inter", sans-serif;
        font-size: 1.5rem;
        line-height: 2.25rem;
        color: #ffffff;
        @media (max-width: 992px) {
          font-size: 1.1rem;
        }
      }
    }
  }
}

.outsideWwrapper {
  padding: 0 1rem;
  border: 3.5rem solid transparent;
}

.mainHeading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 3.5rem;
  color: #b9d6e5;
  margin-bottom: 5rem;
}

.mainParagraph {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
}

.aboutImg img {
  width: 100%;
}

.parents-saying {
  .parents-heading {
    text-align: center !important;
    color: #ffffff;
  }
}
