.common_gap {
  padding-top: 10rem;
}
.blog {
  h2 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;

    text-align: center;

    color: #b9d6e5;
  }
  .card {
    border-radius: 8px;
    padding: 20px;
    background: rgba(70, 131, 178, 0.25);
    box-shadow: -2px 2px 2px 5px rgba(67, 130, 177, 0.11);
    .card-body {
      padding: 1em 0;
    }
    h5 {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 44px;
      margin-bottom: 0;
      // height: 100px;
      overflow: hidden;
      text-overflow: ellipsis;

      color: #fff;
    }
    p {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.6;
      min-height: 128px;
      /* or 125% */

      color: #f2f2f2;
    }
    span {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.6;
      /* or 125% */

      color: #f2f2f2;
    }
    button {
      width: 100%;
    }
  }
}
.blog-details-contain {
  color: #fff;
}
.comend-section-main {
  .commend-section {
    padding: 10px 0 0 0;
  }
  color: white;
}
.button-one {
  background-color: #4385b6;
  color: #fff;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  border: 0px;
  border-radius: 10px;
  padding: 10px 20px;
  // margin-left: 20px;
}
.listing-right {
  color: #fff;
  ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;

    li {
      padding-bottom: 10px;
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}
.text-left1 {
  text-align: left !important;
  img {
    width: inherit;
    height: inherit;
    max-height: inherit;
    margin-top: inherit;
  }
}
.commend-contain11 {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  img {
    margin-right: 20px;
  }
  h2 {
    text-align: left;
    line-height: normal;
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
    margin-bottom: 0;
  }
  p {
    text-align: left;
    line-height: normal;
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
  }
}
.new-comment-content {
  padding-left: 52px;
}
.reply-button {
  font-size: 15px !important;
  line-height: 18px !important;
}
.text-center11 {
  font-size: 12px !important;
  line-height: 15px !important;
  text-align: center !important;
  margin-top: 15px;
}
.e-text1 {
  color: #fff;
}
.blog-top-one {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: nowrap;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
  s h2 {
    width: 64% !important;
    @media (max-width: 640px) {
      width: 100% !important;
    }
  }
  span {
    color: #fff;
  }
}
.new-comment-content p {
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  color: #fff;
}
.reply-bottom-contain {
  textarea {
    min-height: 120px !important;
  }
}
.pnew-1 {
  @media (max-width: 1024px) {
    padding: 25px !important;
    border: 10px !important;
  }
  @media (max-width: 640px) {
    padding: 15px !important;
    border: 1px !important;
  }
}
.fonttt {
  font-weight: bolder;
}
