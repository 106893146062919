.common_gap {
  padding-top: 10rem;
}
.privacy-style {
  .titleprivacy {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;

    text-align: center;

    color: #b9d6e5;
  }
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 34px;
    text-align: left;
    color: #ffffff;
  }
  label {
    font-family: "Fredoka";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.6;
    /* identical to box height, or 30px */

    color: #ffffff;
  }
  button {
    width: 50%;
  }
}
