@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

$primary: #4385b6;
$danger: #ff4136;
$theme-colors: (
  "primary": $primary,
  "danger": $danger,
);
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
);

$enable-negative-margins: true;
@import "~bootstrap/scss/bootstrap.scss";

// font-family: 'Fredoka';
// font-family:  "Inter", sans-serif !important;

@media (min-width: 1400px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1358px;
  }
}

body {
  font-family: "Inter", sans-serif !important;
  &:not(.width_bg) {
    background-color: #0d1a2d;
  }
  &.width_bg {
    background-image: url("/assets/images/main-bg.png");
    background-attachment: fixed;
    background-size: cover;
    background-color: #272a31;
    background-repeat: repeat;
    height: 100vh;
    width: 100%;
    @media (max-width: 1200px) {
      background-size: 100% 100%;
    }
  }

  &.no-header {
    .header {
      display: none !important;
    }
  }
}

a {
  text-decoration: none !important;
  transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
}

.btn-primary {
  color: #fff;
  border-radius: 8px;
  &:hover {
    color: #fff;
  }
}
.form-control {
  border-radius: 8px;
}

.profile-creation__notation {
  padding-top: 2rem;

  p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #f2f2f2;
  }
}

.width_bg1 {
  background-image: url("/assets/images/main-bg.png");
  background-attachment: fixed;
  background-size: cover;
  background-color: #272a31;
  background-repeat: repeat;
  height: 100vh;
  width: 100%;
  @media (max-width: 1200px) {
    background-size: 100% 100%;
  }
}
.error-page--header {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.97);
  height: 68px;
  left: 0;
  padding: 0 45px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 20;
}
.error-page--content {
  color: #fff;
  h1 {
    font-size: 6em;
    font-weight: 700;
    margin-bottom: 2vw;
    z-index: 1;
  }
  p {
    color: inherit;
    font-size: 2.2em;
    font-weight: 300;
    margin-top: 0;
    // text-shadow: 0 1px 2px rgb(0 0 0 / 57%);
  }
}
.error-page--content--errorCode span {
  border-left: 2px solid #e50914;
  font-size: 2.4em;
  font-weight: 100;
  letter-spacing: 0.1em;
  line-height: 2.2em;
  padding: 0 1vw;
}
.error-main {
  max-width: 500px;
  margin: 0px auto;
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translateY(-50%);
  color: #fff;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4385b6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html {
  scroll-behavior: smooth;
}

.open-eye {
  position: absolute;
  // top: 46px !important;
  // right: 18px !important;
  cursor: pointer;
}




// SLIDER \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

// .swiper {
//   width: 100%;
//   height: 20rem ;
// }

// .swiper-slide {

//   font-size: 18px;
 
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: -webkit-flex;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   -webkit-justify-content: center;
//   justify-content: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   -webkit-align-items: center;
//   align-items: center;
// }

// .swiper-slide img {
//   display: block;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }


pricing-card .pricing-card__pricing h1 span {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #20484f;
  margin-left: 10px !important;
}