.single-wrapper-whole {
  height: 100%;
}

.profile-creation {
  font-family: "Inter", sans-serif !important;
  max-width: 60%;
  margin: 4em auto 6em auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    max-width: 80%;
  }
}

.profile-creation__heading {
  h2 {
    font-family: "Inter", sans-serif !important;
    margin-bottom: 1rem;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    color: #b9d6e5;
    font-family: "Inter", sans-serif;
    @media (max-width: 767px) {
      font-size: 28px;
    }
  }
}

.profile-creation__lists {
  ul {
    font-family: "Inter", sans-serif !important;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    padding-top: 1.5rem;

    li {
      &:not(:last-child) {
        margin-bottom: 0.7rem;
      }
      .profile-cration__lists__item {
        display: flex;
        align-items: center;

        p {
          font-family: "Inter", sans-serif !important;
          margin-bottom: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          margin-left: 0.5rem;
          color: #f4f4f4;
        }
      }
    }
  }
}

.profile-creation__button {
  padding-top: 2rem;

  button {
    font-family: "Inter", sans-serif !important;
    height: 58px;
    background: #4385b6;

    border-radius: 8px;
    display: block;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.1em;
    color: #ffffff;
    border: none;
    font-family: "Inter", sans-serif;
    outline: none;
    text-transform: uppercase;
    border: 1px solid #4385b6;
    transition: 0.5s ease-in-out;

    &:hover {
      background: transparent;
      color: #4385b6;
    }
  }
}

.profile-creation__img {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 991px) {
    justify-content: center;
  }

  .profile-creation-sec {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    // background: #3f535e;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 1024px) {
      width: 280px;
      height: 280px;
    }
    @media (max-width: 576px) {
      width: 200px;
      height: 200px;
    }
    .icon {
      width: 100%;
      max-width: 161px;
      height: 200px;
      @media (max-width: 576px) {
        max-width: 100px;
        height: 100px;
      }
    }
    .edit-profile-button {
      border: none;
      outline: none;
      background: transparent;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.profile-creation__buttonsdadas button {
  font-family: "Inter", sans-serif !important;
  height: 58px;
  background: rgb(180, 12, 12);
  border-radius: 8px;
  display: block;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.1em;
  color: #ffffff;
  border: none;
  font-family: "Inter", sans-serif;
  outline: none;
  text-transform: uppercase;
  border: 1px solid #4385b6;
  transition: 0.5s ease-in-out;
  margin-top: 10px;
}

.delbtn img {
  width: 20px;
  margin-top: -5px;
  margin-left: -27px;
}

.sdaaaaaaaaa {
  font-family: "Inter", sans-serif !important;
  width: 100%;
  max-width: 164px;
  height: 135px;
  text-align: center;
  // background: #3f535e;
  // border-radius: 50%;
  border: none;
  padding: 33px 20px;
  margin: 20px;

  img {
    width: 100%;
    max-width: 65px;
    height: 74px;
  }
}

.new-colm {
  padding-right: 0;
}

#modallll {
  background: #060f1c !important;
}
