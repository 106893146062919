body {
  font-family: "Inter", sans-serif !important;
}

.header_top {
  margin-top: 200px;
}

.loginPAge {
  width: calc(788 / 16 * 1rem);
  margin: 0 auto;
  max-width: 100%;
  padding-bottom: 2rem;

  .loginPageHead {
    padding: 6rem 0 1.5rem;
    text-align: center;

    .logo {
      img {
        height: calc(115 / 16 * 1rem);
      }
    }
  }

  .loginFormWraper {
    background-color: #171c29;
    border-radius: 12px;
    padding: 3rem 10rem;
    @media (max-width: 545px) {
      padding: 3rem 6rem;
    }
    @media (max-width: 447px) {
      padding: 3rem 3rem;
    }
    @media (max-width: 350px) {
      padding: 3rem 1rem;
    }

    h2 {
      font-family: "Inter", sans-serif !important;
      font-style: normal;
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 3rem;
      text-align: center;
      margin-bottom: 3rem;
      color: #b9d6e5;
      @media (max-width: 767px) {
        font-size: 1.5rem;
      }
    }

    .label {
      font-size: 20px;
      text-align: left;
      color: #ffffff;
      margin-bottom: 5px;
    }

    .form-group {
      margin-bottom: 1.5rem;
    }

    .form-control {
      &::placeholder {
        color: rgba(#20484f, 0.2);
      }
    }

    .submit-btn {
      .btn {
        font-size: 1.125rem;
        font-family: "Inter", sans-serif !important;
        font-weight: 700;
      }
    }

    label {
      font-weight: 400;
      font-size: 1rem;
      color: #ffffff;
      line-height: 2.25rem;
      display: flex;
      padding-left: 30px;
      position: relative;
      em {
        font-style: normal;
        i {
          position: absolute;
          left: 0;
          top: 2px;
          padding-top: 0 !important;
        }
        img {
          width: 20px;
          position: absolute;
          top: 4px;
          left: 2px;
          filter: brightness(0) invert(1);
          transform: scale(0);
          transition: 0.3s all ease-in-out 0s;
          z-index: 999;
        }
      }
      input:checked + em img {
        transform: scale(1);
      }

      i {
        padding-top: 12px;

        i {
          display: block;
          box-sizing: border-box;
          width: 24px;
          height: 24px;
          border: 1px solid rgba(255, 255, 255, 0.9);
          border-radius: 4px;
          align-self: flex-start;
        }
      }

      span {
        display: block;
        padding-left: 1rem;
      }

      a {
        color: #9dd5fd;
      }
    }

    .links {
      p {
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 2.25rem;
        text-align: center;
        color: #ffffff;
        text-align: left;
        margin-bottom: 5px;

        a {
          color: #9dd5fd;
        }
      }
    }
  }
  .google_box {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    width: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    margin: auto;
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    p {
      font-size: 20px;
      line-height: 1.4;
      margin: 0;
      padding-left: 15px;
      color: #000;
    }
  }
  .or_class {
    color: #fff;
    text-align: center;
    margin-top: 1em;
    font-size: 23px;
    position: relative;

    &::before {
      content: "";
      border: 2px solid #fff;
      width: 81px;
      height: 50px;
      position: absolute;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-radius: 0;
      top: 45%;
      left: 25%;
      @media (max-width: 712px) {
        left: 15%;
      }
      @media (max-width: 606px) {
        left: 5%;
      }
    }
    &::after {
      content: "";
      border: 2px solid #fff;
      width: 81px;
      height: 50px;
      position: absolute;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-radius: 0;
      top: 45%;
      right: 25%;
      @media (max-width: 712px) {
        right: 15%;
      }
      @media (max-width: 606px) {
        right: 5%;
      }
    }
  }
}

.common-container {
  padding: 1rem 2rem;
  max-width: 100%;
  width: 100%;
  position: relative;
  @media (max-width: 375px) {
    padding: 1rem 1rem;
  }
}

.vdo-bws__label {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  color: #f2f2f2;
}

.fabourites__heading {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  color: #ffffff;

  h2 {
    margin-bottom: 0;
  }
}

.page-heading {
  .page-heading__wrapper {
    width: 100%;
    // text-align: center;
    position: relative;
    margin-bottom: 2.3rem;

    h2 {
      font-style: normal;
      font-weight: 600;
      font-family: "Inter", sans-serif !important;
      font-size: 36px;
      color: #b9d6e5;
      margin-bottom: 0;
      @media (max-width: 767px) {
        font-size: 25px;
      }
    }
  }
}

.back-page {
  position: absolute;
  top: 2rem;
  left: 2rem;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  letter-spacing: 0.1em;
  color: #f2f2f2;
}

.acc-set__lists {
  .acc-set__lists__item {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    .acc-set__list__label {
      label {
        font-weight: 400;
        font-size: 20px;
        color: #f2f2f2;
        margin-bottom: 0.5rem;
      }
    }
  }
}

.acc-set__lists {
  width: 50%;
  margin: auto;
  @media (max-width: 1380px) {
    width: 80%;
  }
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 2em;
  }

  &.w80 {
    width: 80%;
    @media (max-width: 475px) {
      width: 100%;
    }
  }

  &.membership {
    .page-heading__wrapper {
      text-align: center;
    }
  }
}

.settings-wrapper {
  background: #1b2b3a;
  border: 1px solid #9a9a9a;
  border-radius: 5px;
  padding: 1.2rem 2rem;

  p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #f2f2f2;
  }

  .row {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.button-def {
  border: none;
  outline: none;
  background: transparent;
}

.change-pass {
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: underline;
  color: #73bcff;
}

.two-elem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-all;
  @media (max-width: 490px) {
    display: block;
  }
}

.notific {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: underline;
  color: #b9d6e5;
}

.notific-sec {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #f2f2f2;
}

.cus-switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .swslide {
    background: #76ee59;
  }

  input:focus + .swslide {
    box-shadow: 0 0 1px #76ee59;
  }

  input:checked + .swslide:before {
    -webkit-transform: translateY(-50%) translateX(23px);
    -ms-transform: translateY(-50%) translateX(23px);
    transform: translateY(-50%) translateX(23px);
  }
}

.swslide {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: inset 0px 6px 8px 3px rgba(0, 0, 0, 0.1);

  &:before {
    position: absolute;
    content: "";
    height: 15.75px;
    width: 15.75px;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    background: linear-gradient(180deg, #ffffff 0%, #e8eaea 100%);
    filter: drop-shadow(-2px 1px 6px rgba(0, 0, 0, 0.25));
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  &.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
}

.div-switch {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: end;
  @media (max-width: 490px) {
    align-items: flex-start;
  }

  small {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 0.3rem;
  }
}

.pricing-card {
  background: #f5f5f5;
  border-radius: 24px;
  width: 100%;
  max-width: 451.23px;
  padding: 2rem 2rem 1rem;
  margin: 0 15px;
  @media (max-width: 576px) {
    padding: 2rem 1rem 1rem;
  }
  @media (max-width: 1024px) {
    margin: 0 auto;
  }
  &.active {
    border: 2px solid #4d85c8;
    position: relative;
    margin: 0 15px;
    @media (max-width: 1024px) {
      margin: 0 auto;
    }
  }

  .tag {
    background: #4d85c8;
    border-radius: 8px;
    width: 95.61px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 30.84px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -1rem;

    p {
      margin-bottom: 0;
    }
  }

  .pricing-card__heading {
    text-align: center;

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: #20484f;
      font-family: "Inter", sans-serif !important;
      text-transform: uppercase;
      @media (max-width: 576px) {
        font-size: 21px;
      }
    }
  }

  .pricing-card__pricing {
    text-align: center;

    h1 {
      font-family: "Plus Jakarta Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      color: #072125;

      @media (max-width: 576px) {
        font-size: 30px;
      }

      span {
        font-family: "Plus Jakarta Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color: #20484f;
      }
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #20484f;
      text-align: left;
      padding-left: 2rem;
      height: 30px;
    }
  }

  .pricing-card__lists {
    padding: 1rem 0;
    padding-left: 2rem;
    @media (max-width: 576px) {
      padding-left: 0rem;
    }

    .pricing-card__lists__extra {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #072125;
    }

    ul {
      list-style-type: none;
      padding-left: 2rem;

      li {
        position: relative;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #072125;

        &::before {
          content: "";
          height: 2rem;
          width: 2rem;
          display: block;
          position: absolute;
          left: -2.5rem;
          top: 0;
          background: url("./assets/images/icons/done-icon.svg") no-repeat
            center;
        }

        &:not(:last-child) {
          margin-bottom: 1.2rem;
        }
      }
    }
  }

  .pricing-card__button {
    text-align: center;

    button {
      padding: 16px 36px 15px;
      width: 100%;
      max-width: 314px;
      height: 100%;
      max-height: 58px;

      border-radius: 8px;
      font-family: "Inter", sans-serif !important;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #ffffff;
      border: none;
      outline: none;
      transition: 0.5s ease-in-out;
      @media (max-width: 576px) {
        font-size: 14px;
      }

      &.green {
        background: #46a065;
        border: 1px solid #46a065;

        &:hover {
          background: transparent;
          color: #46a065;
        }
      }

      &.blue {
        background: #4d85c8;
        border: 1px solid #4d85c8;

        &:hover {
          background: transparent;
          color: #4d85c8;
        }
      }
    }
  }
}

.xw-100 {
  max-width: 100% !important;
}

.afterline {
  position: relative;

  &::after {
    content: "";
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    position: absolute;
    bottom: -3rem;
    background: #3d3d3d;
    height: 1px;
  }
}

.card-review {
  background: #fff;
  .card-review__body {
    padding: 1em 1.25em;
    p {
      color: #9a9a9a;
      font-family: "Inter", sans-serif !important;
      font-size: 15px;
    }
    h2 {
      font-family: "Inter", sans-serif !important;
      font-weight: 600;
      font-size: 30px;
      margin-top: 10px;
    }
    h6 {
      color: #737373;
      font-family: "Inter", sans-serif !important;
      margin-bottom: 0;
    }
  }

  .card-review__img {
    img {
      width: 100%;
    }
  }
}
.free-trial {
  padding: 1em 0 !important;
  @media (max-width: 1199px) {
    padding: 5em 0 !important;
  }
  p {
    width: 100%;
    max-width: 952px;
    margin: 0 auto;
  }
  .free_btn {
    width: 100%;
    max-width: 366.12px;

    background: #4d85c8;
    border-radius: 8px;
    font-family: "Inter", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.8;

    color: #ffffff;
    &:hover {
      border: 1px solid #4d85c8;
      background-color: transparent;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.cancle-btn-menu-head {
  height: 40px;
  padding: 0 1rem;
  background: #353434;
  border-radius: 8px;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #ffffff;
}

.profile-creation-input {
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #f2f2f2;
    display: block;
    margin-bottom: 0.4rem;
  }

  input {
    width: 100%;
    height: 51px;
    background: #ffffff;
    border: 1px solid rgba(32, 72, 79, 0.1);
    border-radius: 8px;
    padding: 0 1rem;

    &:focus {
      outline: none;
    }
  }
}

.custom-checkbox {
  label {
    height: 24px;
    width: 24px;
    border: 1px solid rgba(253, 253, 253, 0.7);
    border-radius: 4px;
    position: relative;
    display: block;

    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1;
      cursor: pointer;
    }

    span {
      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 8px;
        top: 2px;
        width: 7px;
        height: 15px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }

    input:checked ~ span:after {
      display: block;
    }
  }
}

// ************ avatar**************//
.icon_bg {
  width: 100%;
  max-width: 162px;
  height: 162px;

  text-align: center;
  background: #3f535e;
  border-radius: 50%;
  padding: 32px 0;

  img {
    width: 100%;
    max-width: 88px;
    height: 95px;
  }
}

.first_profile-section {
  h6 {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;

    text-align: center;

    color: #f2f2f2;
    font-family: "Inter", sans-serif !important;
    margin: 3em 0 1.5em 0;
    @media (max-width: 576px) {
      font-size: 25px;
    }
  }

  .plus_sign_bg {
    width: 100%;
    max-width: 162px;
    height: 162px;

    text-align: center;
    background: #3f535e;
    border-radius: 50%;
    padding: 42px 0;

    img {
      width: 100%;
      max-width: 43px;
      height: 77px;
    }
  }
}

// ************ avatar**************//
.modal_pop {
  backdrop-filter: blur(2px);
  background-color: #07101f6e;
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #060f1c !important;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: 0.3rem;
    outline: 0;
  }
  .modal-dialog {
    max-width: 1200px;
  }
}

@media (max-width: 1199px) {
  .Index_outsideWwrapper__2aMXy {
    padding: 0 !important;
    border: none !important;
  }

  .Index_wraper__BhKqa {
    background-color: rgba(15, 22, 37, 0.7);
    border: none !important;
  }
  .Index_layer_one__37Ee2 {
    padding: 1em;
  }
}

.Index_mainHeading__1zsUh {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem !important;
  line-height: 3.5rem;
  color: #b9d6e5;
  margin-bottom: 5rem;
  @media (max-width: 1200px) {
    font-size: 1.25rem !important;
    line-height: 1.5rem !important;
  }
  @media (max-width: 992px) {
    font-size: 1.1rem !important;
  }
}

.Index_mainParagraph__11zmz {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  @media (max-width: 991px) {
    font-size: 21px !important;
  }
  @media (max-width: 767px) {
    font-size: 15px !important;
  }
}

.Index_wraper__BhKqa.Index_afterline__22hnh::after {
  display: none !important;
}
.aboutImg img {
  width: 100%;
}
html {
  scroll-behavior: smooth;
}
.m-height {
  min-height: 550px;
}

.mySwiperss {
  padding: 100px;
  margin-bottom: 70px;
  .parents-saying {
    text-align: center;
    .parents-say-heading {
      text-align: center;
      h2 {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 2.25rem;
        line-height: 3.5rem;
        color: #b9d6e5;
        margin-bottom: 1rem;
      }
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #ffffff;
    }
  }

  .parents-prof {
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }
  .insight-conversation {
    h3 {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 2.25rem;
      line-height: 3.5rem;
      color: #dfe5e9;
      margin-bottom: 1rem;
    }
  }

  .swiper-slide {
    opacity: 0;
    transition: all 0.3s;
  }

  .swiper-slide-active {
    transform-origin: 50% 50% 0px !important;
    transform: translate3d(0px, 0px, 0px) !important;
    opacity: 1 !important;
  }

  .swiper-button-prev:after {
    font-size: 20px !important;
    background: RGBA(255, 255, 255, 0.07);
    opacity: 1;
    padding: 10px 16px 11px 13px;
    box-shadow: none;
    border: 2px solid RGBA(255, 255, 255, 0.21);
    border-radius: 50%;
    color: #fff;
  }
  .swiper-button-prev:hover {
    -webkit-filter: blur(9px); /* Chrome, Safari, Opera */
    filter: blur(1px);
  }
  .swiper-button-next:hover {
    -webkit-filter: blur(9px); /* Chrome, Safari, Opera */
    filter: blur(1px);
  }
  .swiper-button-next:after {
    font-size: 20px !important;
    background: RGBA(255, 255, 255, 0.07);
    opacity: 1;
    padding: 10px 16px 11px 13px;
    box-shadow: none;
    border: 2px solid RGBA(255, 255, 255, 0.21);
    border-radius: 50%;
    color: #fff;
  }

  .insight-conversation {
    p {
      font-size: 18px;
      max-width: 100%;
      width: 800px;
      margin: 0 auto;
    }
  }
}

.parents-say-heading {
  text-align: center;
  h2 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 3.5rem;
    color: #b9d6e5;
    margin-bottom: 1rem;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #ffffff;
  }
}

.dasdada {
  font-size: 14px;
}

.swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 8px)
  );
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 8px)
  );
  display: inline-block;
  border-radius: 50%;
  background: var(--swiper-pagination-bullet-inactive-color, white);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}

.open-eye {
  position: absolute;
  top: 44px !important;
  right: 19px !important;
  cursor: pointer;
}

.loginPAge .loginFormWraper label span {
  display: block;
  padding-left: 1rem;
  line-height: 21px !important;
}

ul {
  list-style-type: none !important;
}

.about .about_body .aboutText li {
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  font-size: 20px !important;
  line-height: 1.9rem;
  color: #ffffff;
  padding-bottom: 2rem;
  margin: 0 0 30px;
}
.Index_layer_three__Bq9z0 .Index_workCard__29yG- .Index_body__1ZRHa p {
  font-family: "Inter", sans-serif;
  font-size: 20px !important;
  line-height: 2.25rem;
  color: #ffffff;
}

// .about{
//   padding: 0rem 0 47px !important;
//   color: #fff;
// }

// .about .about_body {
//   padding: -1px 0px 83px !important;
// }
.Index_longDash__85tG_ {
  padding: 5rem 0;
  margin-top: -40px;
}

.about1 {
  padding: 0rem 0 47px !important;
  color: #fff;
  .about_body {
    padding: -1px 0px 83px !important;
    @media (max-width: 991px) {
      padding: 0 0px 40px;
    }
    h4 {
      font-family: "Inter", sans-serif !important;
      font-style: normal;
      font-weight: 700;
      font-size: 2.25rem;
      line-height: 3.5rem;
      color: #b9d6e5;
      text-align: center;
      margin: 0 0 5rem;
      @media (max-width: 991px) {
        font-size: 20px;
        margin: 0 0 20px;
      }
      @media (max-width: 767px) {
        font-size: 17px;
        margin: 0 0 20px;
        line-height: 20px;
      }
    }
    .aboutText {
      li {
        font-family: "Inter", sans-serif !important;
        font-weight: 400;
        font-size: 20px !important;
        line-height: 1.9rem;
        color: #ffffff;
        padding-bottom: 2rem;

        margin: 0 0 30px;
        @media (max-width: 767px) {
          font-size: 14px;
          font-family: "Inter", sans-serif !important;
          line-height: 22px;
          margin: 0 0 20px;
        }
      }
    }
    .aboutImg {
      text-align: center;
      img {
        width: auto;
        @media (max-width: 991px) {
          width: 100%;
        }
      }
    }
  }
}

.how-it-works p {
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  font-size: 20px !important;
  line-height: 1.9rem;
  color: #ffffff;
  padding-bottom: 2rem;
}
