.profile-creation-avatar{
    width: 100%;
    max-width: 70%;
    margin: auto;
    height: 100%;
    @media(max-width:1366px){
        max-width: 80%;
    }
    @media(max-width:1100px){
        max-width: 90%;
    }
    // @media(max-width:1024px){
    //     max-width: 80%;
    // }
    // @media(max-width:767px){
    //     max-width: 90%;
    // }
    .profile-creation__heading h2{
        margin:1em 0 1.5em 0 ;
    }
}




.avteredit{
    cursor: pointer;
  
    justify-content: space-between;
margin: 20px !important;
 ;
}