.slick-slide {
  padding: 0.5rem;
}

.rec-slider {
  overflow: hidden;

  border: 2px solid #0d1a2d;
  overflow: hidden;
  border-radius: 10px;

  &:focus-visible {
    outline: none;
  }

  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    // object-position: top;
    transition: 0.3s all ease-in-out;
    border-radius: 10px;
    &:focus-visible {
      outline: none;
    }

    // &:hover {
    //     transform: scale(1.1);
    // }
  }

  &:hover {
    border-color: #ddd;
    border-radius: 10px;

    img {
      transform: scale(1.03);
      transition: 0.3s all ease-in-out;
    }
  }
}

.reset-foucs-v {
  transition: 0.3s all ease-in-out;
  // padding: 5px;
  overflow: hidden;
  &:focus-visible {
    outline: none;
  }

  img {
    border-radius: 10px;
    &:focus-visible {
      outline: none;
    }
  }
  &:hover {
    border-color: 2px solid #ddd;
    border-radius: 10px;

    img {
      transform: scale(1.03);
      transition: 0.3s all ease-in-out;
    }
  }
  &.profile_img {
    overflow: initial;
  }
}

.vdo-bws__wrapper {
  .slick-slider {
    .slick-arrow {
      height: 50%;

      &.slick-next {
        background: url("../../assets/images/icons/right-arrow.svg") no-repeat;
        background-size: 50px;
        background-position: 55%;
        right: -25px;
        @media (max-width: 375px) {
          right: -15px;
        }

        &::before {
          display: none;
        }

        &.slick-disabled {
          opacity: 0;
        }

        &.slick-disabled {
          &::before {
            opacity: 0;
          }
        }
      }

      &.slick-prev {
        background: url("../../assets/images/icons/left-arrow.svg") no-repeat;
        background-size: 50px;
        background-position: 42%;
        left: -15px;
        z-index: 15;
        @media (max-width: 1024px) {
          left: -12px;
        }
        @media (max-width: 375px) {
          left: -15px;
        }

        &::before {
          display: none;
        }

        &.slick-disabled {
          opacity: 0;
        }

        &.slick-disabled {
          &::before {
            opacity: 0;
          }
        }
      }
    }
  }
}

.channel-icon {
  img {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
    margin: auto;
  }
}

.vdo-bws__wrapper {
  .slick-track {
    margin-left: 0;
  }
}

.vdo-bws__row {
  padding-bottom: 1.3rem;
  .channel_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      padding: 10px 0;
      font-weight: 500;
      font-size: 15px;
      text-transform: uppercase;
      color: #fff;
      &:hover {
        color: rgb(168, 168, 168);
      }
    }
  }
}

.modal_pop_full {
  padding: 0 !important;
  .modal-dialog {
    max-width: auto !important;
    width: 100% !important;
  }

  .modal-body {
    padding: 0 !important;
  }

  .modal-content {
    padding: 0 !important;
  }
}

// .sdadadadada {
//   scroll-behavior: smooth;
// }

.bws__wrapper2 {
  display: flex;
  overflow-x: scroll;
}

@media (max-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}
@media (max-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media (max-width: 991px) {
  .channel-icon {
    img {
      width: 7rem !important;
      height: 7rem !important;
    }
  }
}

@media (max-width: 768px) {
  .channel-icon {
    img {
      width: 10rem !important;
      height: 10rem !important;
    }
  }
  .swiper-slide {
    width: 170.5px !important;
    margin-right: 0px !important;
  }
}

@media (min-width: 1446px) and (max-width: 2560px) {
  .channel-icon img {
    width: 12rem !important;
    height: 12rem !important;
  }
}
@media (min-width: 1080px) and (max-width: 1920px) {
  .channel-icon img {
    width: 10rem !important;
    height: 10rem !important;
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .channel-icon img {
    width: 8rem !important;
    height: 8rem !important;
  }
}
html {
  scroll-behavior: smooth !important;
}
