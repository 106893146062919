.footer-logo__txt{
    text-align: center;
    p{
        margin-bottom: 0;
    }
}

.footer{
    text-align: center;
    padding: 1rem ;

    .footer-logo{
        img{
            width: 39px;
        }
    }

    .footer-logo__txt{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #FFFFFF;
    }
    .container-fluid{
        max-width: 70%;
        @media(max-width:1528px){
            max-width: 80%;
        }
        @media(max-width:1340px){
            max-width: 90%;
        }
        @media(max-width:1195px){
            max-width: 100%;
        }
    }
    a{
        color: #fff;
        font-size:16px;
    }
    button{
        color: #fff;
        font-size: 25px;
        @media(max-width:380px){
            font-size: 16px;
        }
        &:focus{
            outline: none;
            box-shadow: none;
        }
        &:hover{
            color: #B9D6E5;
        }
    }
}

@media(max-width:2560px){
    .space-text{
        padding-right: 16px;
    }
  }