.af-login {
  position: sticky !important;
  top: 0;
  z-index: 10;
  background: rgba(15, 22, 37, 0.5);

  button:focus {
    outline: none;
    box-shadow: none;
  }

  .logo-af {
    img {
      width: 53px;
    }
  }

  .af-header {
    padding: 13px 0;
  }

  .container__af-login {
    padding: 0 2rem;
  }

  .user-img img {
    // background: #3f535e;
    width: 60px;
    height: 55px;
    // border-radius: 50%;
  }

  .user-img,
  .af-header__username {
    flex: 0 0 auto;
  }

  .user-img {
    display: flex;
    // align-items: center;
    justify-content: center;
  }

  .af-header__username {
    p {
      margin-bottom: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      letter-spacing: 0.1em;
      margin-right: 0.5rem;
      color: #f2f2f2;
    }
  }

  .nav-links {
    @media (max-width: 991px) {
      background: #2c2f37;
      position: fixed;
      z-index: 20;
      right: -2000%;
      top: 0;
      padding: 2em 1em;
      height: 100vh;
      transition: 0.2s ease-in-out;

      &.active {
        right: 0;
        transition: 0.2s ease-in-out;
      }
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      @media (max-width: 991px) {
        flex-direction: column;
      }

      li {
        padding: 0 0.8rem;

        @media (max-width: 991px) {
          padding: 11px 3.8rem;
        }

        a {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          text-align: center;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: #f2f2f2;

          @media (max-width: 1250px) {
            font-size: 15px;
          }

          @media (max-width: 991px) {
            text-align: left;
          }

          img {
            margin-right: 0.3rem;
          }
        }

        span {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          text-align: center;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: #f2f2f2;
          cursor: pointer;

          @media (max-width: 1250px) {
            font-size: 15px;
          }

          @media (max-width: 991px) {
            text-align: left;
          }

          img {
            margin-right: 0.3rem;
          }
        }
      }
    }
  }

  .profile_name_sec {
    position: relative;
    z-index: 15;
    cursor: pointer;

    @media (max-width: 991px) {
      margin-left: auto;
    }
  }

  .cross_btn {
    display: none;

    @media (max-width: 991px) {
      display: block;
      position: absolute;
      color: #fff;
      font-size: 20px;
    }
  }

  .display_none {
    display: none;
  }
}

.dropdown {
  background: #171717;
  border-radius: 5px;
  width: 100%;
  max-width: 269px;
  height: 350px;
  position: absolute;
  z-index: 10;
  // right: 8px;
  right: 38px;
  top: 10%;

  ul {
    padding: 4.5em 1em 0 1em;
  }

  ul li {
    list-style: none;
    font-family: "Fredoka";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 2.6;
    /* identical to box height, or 219% */

    letter-spacing: 0.1em;

    color: #f2f2f2;
  }

  span {
    color: #f2f2f2;
    padding-left: 15px;
    cursor: pointer;
  }
}

.spinner-border-sm {
  width: 20px;
  height: 20px;
  border-width: 0.2em;
}

.dropdown ul {
  padding: 3.9em 1em 0 0em !important;
}

@media (min-width: 1281px) and (max-width: 1920px) {
  .mediaQ {
    font-size: 20px !important;
  }
}

span {
  font-family: "Inter", sans-serif;
}

.header__username {
  font-family: "Inter", sans-serif;
}
