.user-info__img {
    text-align: center;
    img {
        height: 10rem;
        width: 10rem;
        border-radius: 50%;
        object-fit: cover;
        object-position: top;
    }
}

.user-info__deta {
    p {
        margin-bottom: 0;
    }

    p.user-info__deta__name {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 0.05em;
        color: #F2F2F2;
    }

    p.user-info__deta__designation {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #F2F2F2;
        margin-bottom: 1rem;
    }

    ol,
    p.user-info__deta__anshead {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #F2F2F2;
    }

    button.user-info__deta__readmore {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        background: transparent;
        border: none;
        outline: none;
        text-transform: capitalize;
        color: #A4C7FC;
    }
}

.user-info {
    display: flex;
    align-items: flex-start;
    @media(max-width:767px){
        display: block;
    }

    .user-content-right {
        padding-left: 30px;
        @media(max-width:767px){
            padding-left: 0;
        }
    }

    .user-info__deta__lists {
        padding-left: 16px;
    }
}