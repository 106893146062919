.common_gap {
  padding-top: 10rem;
}

.member-switch__box {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 20px;
    color: #ffffff;
    @media (max-width: 576px) {
      font-size: 20px;
    }
  }
}

.member-ma {
  position: relative;
  display: inline-block;
  width: 85px;
  height: 38px;
  margin: 0 1rem;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .thumbx {
    background: #76ee59;
  }
  input:focus + .thumbx {
    box-shadow: 0 0 1px #76ee59;
  }
  input:checked + .thumbx:before {
    -webkit-transform: translateY(-50%) translateX(46px);
    -ms-transform: translateY(-50%) translateX(46px);
    transform: translateY(-50%) translateX(46px);
  }
}

.thumbx {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #efefef;
  box-shadow: inset 0px 6px 8px 3px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: inset 0px 6px 8px 3px rgba(0, 0, 0, 0.1);

  &:before {
    position: absolute;
    content: "";
    height: 29.75px;
    width: 29.75px;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    background: linear-gradient(180deg, #ffffff 0%, #e8eaea 100%);
    filter: drop-shadow(-2px 1px 6px rgba(0, 0, 0, 0.25));
    -webkit-transition: 0.4s;
    transition: 0.4s;
    @media (max-width: 320px) {
      left: -1px;
    }
  }

  &.round {
    border-radius: 34px;
    &:before {
      border-radius: 50%;
    }
  }
}

.membership-card__cards {
  display: flex;
  justify-content: space-around;
  padding: 3rem 0;
  @media (max-width: 1024px) {
    display: block;
  }
}

.membership-card__row {
  max-width: 90%;
  margin: auto;
  @media (min-width: 1800px) {
    max-width: 58%;
    margin: auto;
  }
  @media (min-width: 1900px) {
    max-width: 50%;
    margin: auto;
  }
}

.plan-tabs {
  .nav-tabs {
    justify-content: center;
    border: none;
    background: #fff;
    max-width: 327px;
    margin: auto;
    border-radius: 25px;
    @media (max-width: 382px) {
      background: transparent;
    }
    .nav-link {
      padding: 5px 50px;
      border-radius: 25px;
      color: #888;
      font-size: 18px;
      margin-bottom: 0;
      @media (max-width: 390px) {
        padding: 5px 51px;
        border-radius: 25px;
        color: #888;
        font-size: 17px;
        margin-bottom: 0;
      }
      @media (max-width: 387px) {
        padding: 5px 52px;
        border-radius: 25px;
        color: #888;
        font-size: 16px;
        margin-bottom: 0;
      }
      @media (max-width: 384px) {
        padding: 5px 55px;
        border-radius: 25px;
        color: #888;
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    background-color: #46ace4;
    border-color: #46ace4;
    color: #fff;
  }

  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border-color: transparent;
  }
}

.plan-tabs .nav-tabs {
  justify-content: center;
  border: none;
  background: #fff;
  max-width: 347px !important;
  margin: auto;
  border-radius: 32px !important;
}


