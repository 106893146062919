.common_gap {
    padding-top: 10rem;
  }
.how-it-works{
    h2{
         font-family: "Inter", sans-serif !important;
        font-style: normal;
        font-weight: 700;
        font-size: 2.25rem;
        line-height: 3.5rem;
        color: #b9d6e5;
       text-align: center;
    }
    h3{
        font-family: "Inter", sans-serif !important;
       font-style: normal;
       font-weight: 700;
       font-size: 1.55rem;
       line-height: 3.5rem;
       color: #b9d6e5;
      text-align: left;
   }
    p{
         font-family: "Inter", sans-serif !important;;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: #ffffff;
        padding-bottom: 2rem;
span{
    font-size: 20px;
    font-weight: 700;
    width: fit-content;
    border-bottom: 1px solid #fff;
}
li{
    list-style: decimal;
}
    }
    img{
        width: 100%;
        height: 100%;
        max-height: 300px;
        margin-top: 1em;
    }
    
    
    
}