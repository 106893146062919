.header {
  padding: 13px 0;
  position: fixed;
  width: 100%;
  background: rgba(15, 22, 37, 0.5);
  z-index: 1;
  top: 0;
  backdrop-filter: blur(5px);

  .logo {
    img {
      height: calc(70 / 16 * 1rem);
      width: auto;
    }
  }

  ul {
    margin-left: -1rem;
    li {
      padding: 0 1.3rem;
      text-align: center;
      a {
        font-family: "Inter", sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 3rem;
        color: #ffffff;
        display: block;
        border-radius: 8px;
        @media (max-width: 1400px) {
          font-size: 1rem;
        }
        &.selected {
          color: #c2e2ff;
        }
        &:hover {
          transform: translateY(-4px);
          color: #c2e2ff;
        }
      }
      &:nth-last-child(2) {
        a {
          background-color: #4385b6;
          color: #fff;
          width: calc(116 / 16 * 1rem);
          text-align: center;
          width: calc(183 / 16 * 1rem);
          font-size: 1.125rem;
          font-weight: 600;
          font-family: "Inter", sans-serif !important;
          &:hover {
            background-color: #fff;
            color: #4385b6;
          }
        }
      }
      &:nth-last-child(1) {
        padding-left: 0rem;
        @media (max-width: 1200px) {
          padding: 1.3rem 0 0 1.3rem;
          margin: 0 auto;
        }
        a {
          background-color: #c2e2ff;
          color: #072125;
          width: calc(116 / 16 * 1rem);
          text-align: center;
          font-size: 1.125rem;
          font-weight: 700;
          font-family: "Inter", sans-serif !important;
          &:hover {
            background-color: #fff;
          }
        }
      }
    }
  }
}
// .navbar-collapse {
//   ul{
//     display: block !important;
//   }
// }

.menu-flex {
  display: flex;
  @media (max-width: 1200px) {
    position: fixed;
    right: -2000%;
    top: 0;
    height: 100vh;
    transition: 0.2s ease-in-out;
    &.active {
      right: 0;
      transition: 0.2s ease-in-out;
    }
  }
  #navbarSupportedContent {
    position: relative;
    .cross_btn {
      display: none;
      @media (max-width: 1200px) {
        display: block;
        position: absolute;
        color: #fff;
        font-size: 20px;
      }
    }
    ul {
      @media (max-width: 1200px) {
        flex-direction: column;
        height: 100%;
        background: #2c2f37;

        justify-content: flex-start !important;
        padding: 20px;
      }
    }
  }
}
.navbar-toggler-icon {
  color: #4385b6;
}
