.video_pop {
  h3 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 1.6;
    /* or 54px */

    color: #ffffff;
    margin-bottom: 0;
  }
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.4;
    /* or 30px */

    display: flex;
    align-items: center;

    color: #ffffff;
  }
}
.youtube-container {
  overflow: hidden;
  width: 100%;
  /* Keep it the right aspect-ratio */
  aspect-ratio: 16/9;
  /* No clicking/hover effects */
  // pointer-events: none;

  // iframe {

  // 	width: 300%;
  // 	height: 100%;

  // 	margin-left: -100%;
  // }
}

.vedioContainer {
  position: relative;
}
.fullScreenIcon {
  color: white;
  position: absolute;
  bottom: 3px;
  right: 119px;
  border: none;
  background: none;
  font-size: 20px;
  @media (width: 1324px) {
    right: 119px;
    bottom: 7px;
  }
  @media (width: 1024px) {
    right: 119px;
    bottom: 7px;
  }
  @media (width: 820px) {
    right: 119px;
    bottom: 7px;
  }
  @media (width: 768px) {
    right: 119px;
    bottom: 7px;
  }
  @media (width: 545px) {
    right: 119px;
    bottom: 7px;
  }
  @media (max-width: 447px) {
    right: 119px;
    bottom: 7px;
  }
  @media (max-width: 350px) {
    right: 119px;
    bottom: 7px;
  }
}

.load_view {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 1;
  width: 100%;
  height: 348px;
}
.paused_view {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: black;

  z-index: 1;
  width: 100%;
  height: 348px;

  .large {
    height: "100%";
  }
}

.ended_view {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: black;
  cursor: pointer;
  z-index: 1;
  width: 640px;
  height: 360px;
}
.iconeditt {
  color: white;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.ytp-cued-thumbnail-overlay {
  width: 100% !important;
}
#player {
  width: 100% !important;
}
.fa-play {
  font-size: 97px !important;
  &::before {
    content: "\f04b";
    position: absolute;
    left: 46%;
    /* right: 0; */
    top: 33%;
  }
}
.fa-repeat {
  font-size: 97px !important;
  &::before {
    position: absolute;
    left: 46%;
    /* right: 0; */
    top: 33%;
  }
}

.company-logo {
  text-align: center;
  padding-top: 20px;
  img {
    width: 45px;
  }
}
.video-text {
  position: absolute;
  bottom: 0;
  left: 326px;
  // opacity: 0.1;
  /* font-size: 50px; */
  p {
    font-size: 40px;
    opacity: 0.1;
  }
}

.btn-close {
  color: #ffffff;
}

.sadada {
  margin-left: -11px;
  padding-top: -15px;
}

.hideright {
  float: right;
  margin-top: -48px;
}
.sadada {
  margin-left: -15px;
  padding-top: -15px;
  margin-top: 19px !important;
  /* line-height: 5.3; */
}

.video_pop h3 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 1.6;
  margin-top: 20px !important;
  color: #ffffff;
  margin-bottom: 31px !important;
}
