.about {
  padding: 10rem 0 50px;
  color: #fff;
  .about_body {
    padding: 0 0px 80px;
    @media (max-width: 991px) {
      padding: 0 0px 40px;
    }
    h4 {
      font-family: "Inter", sans-serif !important;
      font-style: normal;
      font-weight: 700;
      font-size: 2.25rem;
      line-height: 3.5rem;
      color: #b9d6e5;
      text-align: center;
      margin: 0 0 5rem;
      @media (max-width: 991px) {
        font-size: 20px;
        margin: 0 0 20px;
      }
      @media (max-width: 767px) {
        font-size: 17px;
        margin: 0 0 20px;
        line-height: 20px;
      }
    }
    .aboutText {
      li {
        font-family: "Inter", sans-serif !important;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: #ffffff;
        padding-bottom: 2rem;

        margin: 0 0 30px;
        @media (max-width: 767px) {
          font-size: 14px;
          font-family: "Inter", sans-serif !important;
          line-height: 22px;
          margin: 0 0 20px;
        }
      }
    }
    .aboutImg {
      text-align: center;
      img {
        width: auto;
        @media (max-width: 991px) {
          width: 100%;
        }
      }
    }
  }
}
