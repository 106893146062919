.common_gap {
  padding-top: 10rem;
}
.contact {
  h2 {
    font-family: "Inter", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    margin-top: 24px;
    text-align: center;

    color: #b9d6e5;
  }
  p {
    font-family: "Inter", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 34px;
    text-align: left;
    color: white;
  }
  label {
    font-family: "Fredoka";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.6;
    /* identical to box height, or 30px */

    color: #ffffff;
  }
  button {
    width: 50%;
  }
}
.a {
  color: white !important;
  li {
    list-style: circle !important;
    a {
      color: white !important;
    }
  }
}
