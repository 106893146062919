.search-page__input {
  position: relative;
  .search-icon{
  position: absolute;
  top: 8px;
  right: 7px;
  img{
    filter: invert(1);
  }
  button{
    border: none;
    background: transparent;
  }
  }
  input {
    background: #d9d9d9;
    border-radius: 10px;
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    padding: 0.5rem 1rem;
    caret-color: #606060;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    color: #b9b9b9;

    &:focus-visible {
      outline: none;
    }
  }
}

.search-page__result {
  padding-top: 2rem;
}

.search-page__result__img {
  img {
    width: 100%;
    height: 100%;
  }
}


